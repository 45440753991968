<template>
  <span class="card" @click="$emit('show-card', item)">
    <div class="d-flex justify-space-between">
      <label class="label">
        #{{ item.id }} <strong><a-view-select :value="item.type" :values="item" :model="getModelByName('type')" /></strong>
      </label>
      <a-view-select :value="item.data.side" :model="getModelByName('data.side')" />
      <small
        ><span v-if="item.from_id">{{ item.from_id }}<</span> <span v-if="item.next_id">>{{ item.next_id }}</span>
      </small>
    </div>

    <div class="kanban-action">
      <label> </label>
    </div>

    <label class="label">
      <div class="d-flex">
        <a-view-text :value="item.content" :values="item" />
      </div>
    </label>
    <v-btn class="mx-2" fab dark x-small color="green" @click="$emit('click-create', item.id)">
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
  </span>
</template>

<script>
import { getAccess, autoHeightBlock, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, autoHeightBlock, genModel],
  name: "LeadsCardKanban",
  props: {
    item: Object,
    model: { type: Array, default: () => [] },
  },
  components: {},
  data() {
    return {
      lazy: false,
      m: this.$store.getters["config/get"].models.leads,
      load_action: 0,
      actions_: [],
      load_task: 0,
      tasks_: [],
      type: [...this.$store.state.config.data.config.dictionary.lead_actions],
    };
  },

  computed: {},
  watch: {
    "item.updatedon"() {
      this.load_task = 0;
      this.load_action = 0;
    },
  },
  mounted() {},
  methods: {},
};
</script>
